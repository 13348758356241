<template>
    <div class="app-main__inner">
        <div class="container mb-3 dm-sans-font">
            <div class="bg-white mb-3">
                <div class="row align-items-center py-2 px-3">
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="md:mt-10">
                                <button :disabled="connectedBtn || authorizing" class="px-3 py-2 bg-green-500 text-white h-10 rounded w-full" @click="authorizeFortnox">
                                    <span v-if="authorizing" class="flex justify-center">
                                        <svg class="animate-spin h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    </span>
                                    <span v-else>{{ $t('connectFortnox') }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center py-2 px-3">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="inputCompanyName" class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{ $t('approachingLowStockQty') }}<span class="help-tooltip cursor-pointer" :title="$t('approaching_low_stock_quantityHelpText')"></span></label>
                            <div>
                                <input type="number" min="0" v-model="fortnoxForm.approachingLowQty" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="inputCompanyName" class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{ $t('criticalLowStockQty') }}<span class="help-tooltip cursor-pointer" :title="$t('critical_low_stock_quantityHelpText')"></span></label>
                            <div>
                                <input type="number" min="0" v-model="fortnoxForm.criticalLowQty" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="inputCompanyName" class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{ $t('sufficientQty') }}<span class="help-tooltip cursor-pointer" :title="$t('sufficient_stock_quantityHelpText')"></span></label>
                            <div>
                                <input type="number" min="0"  v-model="fortnoxForm.sufficientQty" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col p-2">
                    <div class="form-group flex align-items-center space-x-3">
                        <div>
                            <input type="checkbox" v-model="fortnoxForm.syncStockWebshop" class="checkbox-height  h-4 w-4">
                        </div>
                        <label class="field-label">{{ $t('syncStockFromSupplier') }}<span class="help-tooltip cursor-pointer" :title="$t('syncStockFromSupplierHelpText')"></span></label>
                    </div>
                    <div class="form-group flex align-items-center space-x-3">
                        <div>
                            <input type="checkbox" v-model="fortnoxForm.automateOrder" class="checkbox-height  h-4 w-4">
                        </div>
                        <label class="field-label">{{ $t('placeAutomaticOrders') }}<span class="help-tooltip cursor-pointer" :title="$t('placeAutomaticOrdersHelpText')"></span></label>
                    </div>
                </div>

                <div class="flex justify-center mb-5">
                    <button @click="saveUserFortnoxData" :disabled='connecting' class="flex justify-center align-items-center space-x-4 bg-green-500 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-11/12 md:w-1/3 mb-5">
                        <span v-if="connecting" class="flex align-items-center space-x-3">
                            <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            {{ $t('updating') }}
                        </span>
                        <span v-else>{{ $t('update') }}</span>
                    </button>
                </div>
            </div>
        </div>
        <Notification />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name : 'AddFortnox',
        components : {
            Notification : () => import('./../../customComponent/NotificationAlert'),
        },
        data () {
            return {
                authorizing : false,
                showAuthorize : true,
                connecting : false,
                connectedBtn : false,
                fortnoxForm : {
                    approachingLowQty : '',
                    criticalLowQty : '',
                    sufficientQty : '',
                    syncStockWebshop : false,
                    automateOrder : false
                },
                checkedOnce : null,
                queryName : null
            }
        },
        computed : {
            ...mapGetters({
                // GET_SHOP_LIST : 'customer/GET_SHOP_LIST',
                GET_CONNECTED_SHOP_LIST : 'customer/GET_CONNECTED_SHOP_LIST',
                GET_WEBSHOP_PROVIDER : 'customer/GET_WEBSHOP_PROVIDER'
            })
        },
        mounted () {
            // window.location.href = 'add-shopify'
            this.getProvider()
            this.getShopList()
            this.getFortnoxInfo()
        },
        methods : {
            getFortnoxInfo () {
                const data = this.$route.query.integration
                if (data) {
                    const payload = {
                        edit : true,
                        webshopId : data
                    }
                    this.$store.dispatch('customer/getConnectedWebshop', payload)
                    .then(res => {
                        // this.fortnoxForm.shopName = res.data.shop_name
                        // this.fortnoxForm.note = res.data.note
                        // this.fortnoxForm.companyId = res.data.company.id
                        this.fortnoxForm.automateOrder = res.data.place_automatic_orders
                        this.fortnoxForm.sufficientQty = res.data.sufficient_stock_quantity
                        this.fortnoxForm.approachingLowQty = res.data.approaching_low_stock_quantity
                        this.fortnoxForm.criticalLowQty = res.data.critical_low_stock_quantity
                        this.fortnoxForm.syncStockWebshop = res.data.sync_stock_quantity_from_supplier_to_integration
                    })
                    .catch(err => {
                        if (err.response.status === 401) {
                            this.$store.commit('auth/CLEAR_AUTH_CUS', null)
                            window.Bus.$emit('sign-out')
                        }
                    })
                }
            },
            getShopList () {
            	let url = `?is_connected=True`
                this.$store.dispatch('customer/getConnectedShopList', { data : url })
            },
            getProvider () {
                this.$store.dispatch('customer/getIntegrationProvider')
                .then(_ => {
                    const { code, state, hmac, host, shop, timestamp } = this.$route.query
                    if (code && state && hmac && host && shop && timestamp) {
                        const check = window.localStorage.getItem('checkonce')
                        if (!check) {
                            this.sendResponseAutomatically()
                        }
                    }
                })
                .catch(_ => {})
            },
            authorizeFortnox () {
                this.authorizing = true
                const payload = {
                    query : '?is_logged_in_user=True',
                }
                this.$store.dispatch('customer/authorizeFortnox', payload)
                .then(res => {
                    this.authorizing = false
                    // this.saveInformation()
                    setTimeout(() => {
                        window.open(res.data.url, '_self')
                    }, 1000)
                })
                .catch(err => {
                    this.authorizing = false
                    this.$services.helpers.notification(err.response.data.error, 'error', this)
                    if (err.response.status === 401) {
                        this.$store.commit('auth/CLEAR_AUTH_CUS', null)
                        window.Bus.$emit('sign-out')
                    }
                })
            },
            saveInformation () {
                const saveShopify = window.localStorage.getItem('__shopify__data')
                if (!saveShopify) {
                    const data = { shop_name : this.fortnoxForm.shopName }
                    window.localStorage.setItem('__shopify__data', JSON.stringify(data))
                }
            },
            sendResponseAutomatically () {
                const loading = this.$loading({
                    lock: true,
                    text: this.$t('pleaseWait'),
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })
                window.localStorage.setItem('checkonce', true)
                const filterProvider = this.GET_WEBSHOP_PROVIDER.filter(item => item.name === 'Shopify')
                // const data = window.localStorage.getItem('__shopify__data')
                // const companyId = JSON.parse(data).company_id
                // this.fortnoxForm.companyId = companyId
                this.fortnoxForm.shopName = this.fortnoxForm.shop
                const payload = {
                    host : this.fortnoxForm.host,
                    hmac : this.fortnoxForm.hmac,
                    state : this.fortnoxForm.state,
                    timestamp : this.fortnoxForm.timestamp,
                    code : this.fortnoxForm.code,
                    shop : this.fortnoxForm.shop,
                    // company_id : companyId,
                    provider : filterProvider[0].id
                }
                this.$store.dispatch('customer/connectShopify', payload)
                .then(res => {
                    loading.close()
                    this.connecting = false
                    this.$services.helpers.notification(this.$t('updatedWebshop'), 'success', this)
                    const data = window.localStorage.getItem('__shopify__data')
                    const storageData = JSON.parse(data)
                    storageData.webshop_id = res.data.web_shop_id
                    window.localStorage.setItem('__shopify__data', JSON.stringify(storageData))
                    // this.fortnoxForm.companyId = storageData.company_id
                    this.fortnoxForm.shopName = this.fortnoxForm.shop
                })
                .catch(err => {
                    this.autoError = true
                    loading.close()
                    this.connecting = false
                    this.$services.helpers.notification(err.response.data.error, 'error', this)
                    setTimeout(() => {
                        window.location.href = 'add-shopify'
                    }, 2000)
                    if (err.response.status === 401) {
                        this.$store.commit('auth/CLEAR_AUTH_CUS', null)
                        window.Bus.$emit('sign-out')
                    }
                })
            },
            saveUserFortnoxData () {
                const data = this.$route.query.integration
                if (!data) {
                    return this.$services.helpers.notification('Invalid credential', 'error', this)
                }
                const webshop = data

                if (this.fortnoxForm.approachingLowQty === '' || this.fortnoxForm.criticalLowQty === '' ||
                    this.fortnoxForm.syncStockWebshop === '' || this.fortnoxForm.automateOrder === '' || this.fortnoxForm.sufficientQty === '') { // this.fortnoxForm.companyId === ''
                    this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
                } else if (!webshop) {
                    this.$services.helpers.notification(this.$t('invalidUpdateResponse'), 'error', this)
                } else {
                    this.connecting = true
                    const payload = {
                        webshop_id_save : webshop,
                        approaching_low_stock_quantity : this.fortnoxForm.approachingLowQty,
                        critical_low_stock_quantity : this.fortnoxForm.criticalLowQty,
                        place_automatic_orders : this.fortnoxForm.automateOrder,
                        sufficient_stock_quantity : parseInt(this.fortnoxForm.sufficientQty),
                        sync_stock_quantity_from_supplier_to_integration : this.fortnoxForm.syncStockWebshop,
                    }
                    this.$store.dispatch('customer/updateConnectedWebshop', payload)
                    .then(_ => {
                        this.connecting = false
                        this.$services.helpers.notification(this.$t('updatedWebshop'), 'success', this)
                        this.$router.replace({ name : 'webshopoverview' })
                    })
                    .catch(err => {
                        this.connecting = false
                        this.$services.helpers.notification(err.response.data.error, 'error', this)
                        if (err.response.status === 401) {
                           this.$store.commit('auth/CLEAR_AUTH_CUS', null)
                            window.Bus.$emit('sign-out')
                        }
                    })
                }
            },
            clearField () {
                Object.keys(this.fortnoxForm).forEach(element => {
                    if (typeof (this.fortnoxForm[element]) !== 'string') {
                        this.fortnoxForm[element] = false
                    } else {
                        this.fortnoxForm[element] = ''
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
.c-border{
    border-width: 1px !important;
}
.form-control{
    border: 1px solid $form-border !important;
    height: unset !important;
    padding: .175rem .75rem !important;
}
.form-select{
    border: 1px solid $form-border !important;
    height: unset !important;
    padding: .175rem 1.5rem !important;
}
.form-control:focus{
    box-shadow: unset !important;
    border: 1px solid $theme-primary-color !important;
}
.btn-border-all{
    border: 1px solid $theme-secondary-color !important;
    color: $theme-secondary-color !important;
}
.table th, .table td {
    border: unset !important;
}
</style>
